import { useState, useEffect } from "react";
import Alert from "./Alert";
import db from "./firebase";
import Loading from "./Loading";

function App() {
  const [day, setDay] = useState();
  const [hour, setHour] = useState();
  const [minute, setMinute] = useState();
  const [second, setSecond] = useState();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [alert, setAlert] = useState(null);

  useEffect(() => {
    let unSub = handleTime();
    return unSub;
  }, []);

  const handleTime = () => {
    setLoading(true);
    db.collection("launch_date")
      .doc("dateId")
      .onSnapshot((doc) => {
        const launchDate = new Date(
          `${doc.data().date}, 2021 00:00:00`
        ).getTime();

        setInterval(() => {
          const currentTime = new Date().getTime();
          const difference = launchDate - currentTime;
          const day = Math.floor(difference / (1000 * 60 * 60 * 24));
          const hour = Math.floor((difference / (1000 * 60 * 60)) % 24);
          const minute = Math.floor((difference / (1000 * 60)) % 60);
          const second = Math.floor((difference / 1000) % 60);
          setDay(day);
          setHour(hour);
          setMinute(minute);
          setSecond(second);
          setLoading(false);
        }, 1000);
      });
  };

  const handleChange = (e) => {
    setEmail(e.target.value);
    console.log(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      let req = await db.collection("email_list").add({ email });
      setLoading(false);
      setAlert({ success: true, msg: "Successfully Subscribed" });
    } catch (err) {
      setLoading(false);
      setAlert({
        success: false,
        msg: "Something went wrong, please try again",
      });
      console.log(err);
    }
    setEmail("");
  };

  return loading ? (
    <Loading />
  ) : (
    <div className="App">
      <div className="content">
        <h1>
          One stop platform for Nepalese living in the San Francisco Bay Area is
          <span> launching soon</span>{" "}
        </h1>
        <p className="secondary-text muted">
          Enter your email below and stay tuned
        </p>
        <form className="form" onSubmit={handleSubmit}>
          <input
            type="email"
            placeholder="Your Email Address"
            required
            onChange={handleChange}
            value={email}
          />
          <button type="submit">Subscribe</button>
        </form>
        <div className="timer-section">
          <div className="timer">
            <div className="time-wrapper">
              <h3>{day}</h3>
            </div>
            <p className="muted">Days</p>
          </div>
          <div className="timer">
            <div className="time-wrapper">
              <h3>{hour}</h3>
            </div>
            <p className="muted">Hours</p>
          </div>
          <div className="timer">
            <div className="time-wrapper">
              <h3>{minute}</h3>
            </div>
            <p className="muted">Minutes</p>
          </div>
          <div className="timer">
            <div className="time-wrapper">
              <h3>{second}</h3>
            </div>
            <p className="muted">Seconds</p>
          </div>
        </div>
      </div>
      {alert ? <Alert alert={alert} setAlert={setAlert} /> : null}
    </div>
  );
}

export default App;
