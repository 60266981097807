import firebase from "firebase/app";
import  "firebase/firestore"

var firebaseConfig = {
    apiKey: "AIzaSyAmWSLusqB6XnKwnR5iriJJ01qqt_MCWsU",
    authDomain: "shuvakarya-5eb5d.firebaseapp.com",
    projectId: "shuvakarya-5eb5d",
    storageBucket: "shuvakarya-5eb5d.appspot.com",
    messagingSenderId: "293334034721",
    appId: "1:293334034721:web:f0015c30447c23fb3e3660",
    measurementId: "G-BT9TWKYXQ5"
  };

firebase.initializeApp(firebaseConfig);
const db = firebase.firestore()
export default db;