import React, { useEffect } from "react";

const Alert = ({ alert, setAlert }) => {
  useEffect(() => {
    const unsub = setTimeout(() => {
      setAlert(null);
    }, 5000);

    return unsub;
  });

  return (
    <div className={alert.success ? "alert" : "alert fail"}>
      <p>{alert.msg}</p>
    </div>
  );
};

export default Alert;
